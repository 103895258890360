import { PaletteColor, Theme } from '@mui/material';
import { SafetyPredictionType } from '@/api/ui/requests';

export const safetyPredictionTypeColor = (theme: Theme, type?: SafetyPredictionType, isCustomerDefined?: boolean) => {
  return safetyPredictionTypeRankAndColor(theme, type, isCustomerDefined)[1];
};

/**
 * Returns the rank and color of the safety prediction type.
 * Rank is used to determine the order of the colors.
 * below lower limit: 0,
 * above lower limit: 1,
 * above customer upper limit: 2,
 * above upper limit: 3
 * */
export const safetyPredictionTypeRankAndColor = (theme: Theme, type?: SafetyPredictionType, isCustomerDefined?: boolean) => {
  let rankAndColor: [number, PaletteColor] = [0, theme.palette.secondary];
  switch (type) {
    case SafetyPredictionType.ABOVE_UPPER_LIMIT:
      rankAndColor = isCustomerDefined ? [2, theme.palette.above_upper_customer_limit] : [3, theme.palette.above_upper_limit];
      break;
    case SafetyPredictionType.ABOVE_LOWER_LIMIT:
      rankAndColor = [1, theme.palette.above_lower_limit];
      break;
    default:
      break;
  }

  return rankAndColor;
};
