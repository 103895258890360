import React, { memo, useMemo } from 'react';
import { ArrowCircleRightOutlined, DirectionsBoatFilled, ExpandLessOutlined, ExpandMoreOutlined, Warning } from '@mui/icons-material';
import {
  Badge,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Theme,
  Typography,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { NavigateFunction, createSearchParams, useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment/moment';
import { useSafetyPredictionServiceGetBerthsSafetyWarningsByDate } from '@/api/ui/queries';
import { BerthSafetyWarningDetails } from '@/api/ui/requests';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import { BerthMarkerBlackSvg } from '@/features/SafetyPrediction/Icons';
import { safetyPredictionTypeColor, safetyPredictionTypeRankAndColor } from '@/features/color-utils';
import useOrganisation from '@/hooks/useOrganisation';
import { DATE_FORMAT, SafetyPredictionTriggerStrings } from '@/types';

type DailyWarningsDialogProps = {
  day: Moment;
  onClose: () => void;
  selectedBerthIds?: string[] | undefined;
  withAisVesselsOnly?: boolean;
};

type BerthSafetyWarningDetailsProps = {
  berthId: string;
  berthName: string;
  vesselWarnings: BerthSafetyWarningDetails[];
  warningSummaryByColor: Map<
    string,
    {
      rank: number;
      numberOfWarnings: number;
    }
  >;
};

const navigateOnClick = (navigate: NavigateFunction, warning: BerthSafetyWarningDetails) => () => {
  const { berthId, loadingCondition, trigger, dmaCaseId, isOperationalVessel, vesselDisposition, predictionTimestamp, vesselMmsi } = warning;
  const searchParams = createSearchParams({
    dmaCaseId,
    loadingCondition,
    date: moment(predictionTimestamp).format(DATE_FORMAT),
    disposition: vesselDisposition,
    graph: SafetyPredictionTriggerStrings[trigger]?.graphName,
    period: '1',
  }).toString();

  const basePath = isOperationalVessel
    ? `/safety-prediction/operational/berths/${berthId}/vessels/${vesselMmsi}`
    : `/safety-prediction/simulation/berths/${berthId}`;

  navigate({
    pathname: basePath,
    search: searchParams,
  });
};

const warningsByBerth = (warnings: BerthSafetyWarningDetails[], theme: Theme) =>
  warnings.reduce((acc, warning) => {
    const { berthId, berthName, safetyPredictionType, isCustomerDefinedType } = warning;
    const collectedWarning = acc?.get(berthId) ?? {
      berthId,
      berthName,
      vesselWarnings: [],
      warningSummaryByColor: new Map(),
    };
    collectedWarning.vesselWarnings.push(warning);

    const [warningRank, warningColor] = safetyPredictionTypeRankAndColor(theme, safetyPredictionType, isCustomerDefinedType);
    const warningSummary = collectedWarning.warningSummaryByColor.get(warningColor.main) ?? {
      rank: warningRank,
      numberOfWarnings: 0,
    };

    acc.set(berthId, {
      ...collectedWarning,
      warningSummaryByColor: collectedWarning.warningSummaryByColor.set(warningColor.main, {
        ...warningSummary,
        numberOfWarnings: warningSummary.numberOfWarnings + 1,
      }),
    });
    return acc;
  }, new Map<string, BerthSafetyWarningDetailsProps>());

const DailyBerthWarningsDialog = ({ day, selectedBerthIds, withAisVesselsOnly, onClose }: DailyWarningsDialogProps) => {
  const theme = useTheme();
  const { selectedOrganisationId } = useOrganisation();
  const dateTime = day.toISOString(true);
  const { isLoading, data: warnings } = useSafetyPredictionServiceGetBerthsSafetyWarningsByDate(
    {
      xSelectedOrganisationId: selectedOrganisationId,
      berthIds: selectedBerthIds,
      withAisVesselsOnly,
      dateTime,
    },
    [selectedOrganisationId, selectedBerthIds, dateTime, withAisVesselsOnly],
    {
      refetchOnWindowFocus: false,
    }
  );

  const warningsByBerths = useMemo(() => Array.from(warningsByBerth(warnings ?? [], theme).values()), [warnings, theme]);

  const WarningByBerthRows = () => (
    <>
      {warningsByBerths.map((warningsByBerth) => {
        const warnings = warningsByBerth.vesselWarnings.sort((a, b) => {
          const timestampCompare = moment(a.predictionTimestamp).diff(b.predictionTimestamp, 'minutes');
          if (timestampCompare !== 0) return timestampCompare;
          return b.percentage - a.percentage;
        });
        return <WarningsPerBerthRow key={warningsByBerth.berthId} vesselWarnings={warnings} {...warningsByBerth} />;
      })}
    </>
  );

  return (
    <Dialog open={true} onClose={onClose} maxWidth={false}>
      <DialogTitle>
        <Typography variant={'h3'}>Safety levels exceeded on {day.format('MMM D')}</Typography>
      </DialogTitle>
      <DialogContent sx={{ maxWidth: 'fit-content', scrollbarWidth: 'none', px: 0 }}>
        {isLoading ? (
          <Box width={1000} display={'flex'} justifyContent={'center'}>
            <LoadingIndicator message={'Retrieving safety warnings...'} />
          </Box>
        ) : (
          <TableContainer component={Box} sx={{ width: 1000, overflowX: 'initial' }}>
            <Table size={'small'} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align={'right'}>
                    <BerthMarkerBlackSvg />
                  </TableCell>
                  <TableCell align={'left'}>
                    <Typography variant={'headerLarge'}>Berth</Typography>
                  </TableCell>
                  <TableCell align={'right'}>
                    <Typography variant={'headerLarge'}>Affected Vessels</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody sx={{ td: { borderBottom: 'unset' } }}>
                <WarningByBerthRows />
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

const WarningsPerBerthRow = ({ berthId, berthName, vesselWarnings, warningSummaryByColor }: BerthSafetyWarningDetailsProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const WarningSummaryBadges = () => {
    const orderedWarnings = Array.from(warningSummaryByColor.entries(), ([color, { rank, numberOfWarnings }]) => ({
      color,
      rank,
      numberOfWarnings,
    })).sort((a, b) => b.rank - a.rank);

    return (
      <Stack direction={'row-reverse'} spacing={2}>
        {orderedWarnings.map(({ color, numberOfWarnings }) => (
          <Badge
            key={color}
            badgeContent={
              <Chip sx={{ backgroundColor: color, color: 'white', '> span': { p: 0 }, height: 18, width: 18 }} label={numberOfWarnings} />
            }
          >
            <DirectionsBoatFilled sx={{ width: 24, height: 24 }} />
          </Badge>
        ))}
      </Stack>
    );
  };

  return (
    <>
      <TableRow key={berthId}>
        <TableCell>
          <Typography variant={'h6'} align={'right'}>
            {berthId}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'header'} align={'left'}>
            {berthName}
          </Typography>
        </TableCell>
        <TableCell>
          <WarningSummaryBadges />
        </TableCell>
        <TableCell align={'right'}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow key={`warning-${berthId}`}>
        <TableCell colSpan={4} sx={{ p: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer component={Box} sx={{ bgcolor: theme.palette.grey[100], p: 1 }}>
              <Table size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}>
                      <DirectionsBoatFilled sx={{ height: 28, width: 28 }} />
                    </TableCell>
                    <TableCell>Vessel</TableCell>
                    <TableCell>Max %. Threshold</TableCell>
                    <TableCell align={'left'}>Triggered by</TableCell>
                    <TableCell align={'left'}>Vessel Characteristics</TableCell>
                    <TableCell align={'right'}>Time</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vesselWarnings?.map((row) => (
                    <TableRow key={`${row.predictionTimestamp}-${row.berthId}-${row.dmaCaseId}`} sx={{ td: { borderBottom: 'unset' } }}>
                      <TableCell align="center">
                        <Warning sx={{ color: safetyPredictionTypeColor(theme, row.safetyPredictionType, row.isCustomerDefinedType)?.main }} />
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant={'h6'}>{row.vesselName ?? row.vesselClass}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        {row.percentage}% {row.thresholdLabel}
                      </TableCell>
                      <TableCell align="left">{SafetyPredictionTriggerStrings[row.trigger]?.name}</TableCell>
                      <TableCell align="left">{row.isOperationalVessel ? 'AIS detected vessel' : 'Simulated vessel'}</TableCell>
                      <TableCell align="right">{moment(row.predictionTimestamp).local(true).format('HH:mm')}</TableCell>
                      <TableCell align="center">
                        <IconButton aria-label="navigate to safety prediction" onClick={navigateOnClick(navigate, row)}>
                          <ArrowCircleRightOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default memo(DailyBerthWarningsDialog);
