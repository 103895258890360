import { Box, Stack, Tooltip, Typography, styled } from '@mui/material';
import Icon from '@mui/material/Icon';
import Bar from '@/components/common/Bar/Bar';
import { LegendProps } from './types';

const LegendIcon = styled(Icon)(() => ({
  marginRight: '3px',
  verticalAlign: 'middle',
}));

function Legend({ items, alignment, size }: LegendProps) {
  const padding = size === 'small' ? '5px' : '8px';
  const fontSize = size === 'small' ? '1.3rem' : '1.5rem';
  const fontVariant = size === 'small' ? 'bodyRegularSmall' : 'bodyRegularMedium';
  return (
    <Box display="flex" justifyContent={alignment ?? 'left'} mt={1}>
      <Bar sx={{ p: padding }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          {items.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <LegendIcon
                style={{
                  color: item.color,
                  fontSize,
                }}
              >
                {item.icon ?? 'circle'}
              </LegendIcon>
              <Tooltip title={item.description}>
                <Typography variant={fontVariant}>{item.name}</Typography>
              </Tooltip>
            </Box>
          ))}
        </Stack>
      </Bar>
    </Box>
  );
}

export default Legend;
