import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AllowedOrganisation } from '../requests/models/AllowedOrganisation';
import { Bandwidth } from '../requests/models/Bandwidth';
import { BandwidthOption } from '../requests/models/BandwidthOption';
import { Berth } from '../requests/models/Berth';
import { BerthAssignment } from '../requests/models/BerthAssignment';
import { BerthDmaCaseSafetyPrediction } from '../requests/models/BerthDmaCaseSafetyPrediction';
import { BerthDmaCaseSafetyWarning } from '../requests/models/BerthDmaCaseSafetyWarning';
import { BerthFeatures } from '../requests/models/BerthFeatures';
import { BerthSafetyDailyWarnings } from '../requests/models/BerthSafetyDailyWarnings';
import { BerthSafetyResponse } from '../requests/models/BerthSafetyResponse';
import { BerthSafetyWarningDetails } from '../requests/models/BerthSafetyWarningDetails';
import { BerthSafetyWarningSummary } from '../requests/models/BerthSafetyWarningSummary';
import { BerthType } from '../requests/models/BerthType';
import { BollardInformation } from '../requests/models/BollardInformation';
import { Coordinates } from '../requests/models/Coordinates';
import { CustomerParameters } from '../requests/models/CustomerParameters';
import { DetectedVessel } from '../requests/models/DetectedVessel';
import { DmaCase } from '../requests/models/DmaCase';
import { DmaCaseBaseOfSimulation } from '../requests/models/DmaCaseBaseOfSimulation';
import { DmaCaseDefinition } from '../requests/models/DmaCaseDefinition';
import { DmaCaseDefinitions } from '../requests/models/DmaCaseDefinitions';
import { DmaCaseFenderCharacteristics } from '../requests/models/DmaCaseFenderCharacteristics';
import { DmaCaseType } from '../requests/models/DmaCaseType';
import { DmaCaseWithSafetyPrediction } from '../requests/models/DmaCaseWithSafetyPrediction';
import { DmaLineMaterialCharacteristics } from '../requests/models/DmaLineMaterialCharacteristics';
import { DmaMooringLineCharacteristics } from '../requests/models/DmaMooringLineCharacteristics';
import { DmaSupportedVesselType } from '../requests/models/DmaSupportedVesselType';
import { DmaVessel } from '../requests/models/DmaVessel';
import { DmaVesselCharacteristics } from '../requests/models/DmaVesselCharacteristics';
import { DmaVesselLoadBasedCharacteristics } from '../requests/models/DmaVesselLoadBasedCharacteristics';
import { ErrorResponse } from '../requests/models/ErrorResponse';
import { FeedbackRequest } from '../requests/models/FeedbackRequest';
import { GraphThresholdConfiguration } from '../requests/models/GraphThresholdConfiguration';
import { License } from '../requests/models/License';
import { LineMaterial } from '../requests/models/LineMaterial';
import { LoadingCondition } from '../requests/models/LoadingCondition';
import { MetoceanForecast } from '../requests/models/MetoceanForecast';
import { MetoceanSettings } from '../requests/models/MetoceanSettings';
import { MetoceanStation } from '../requests/models/MetoceanStation';
import { MetoceanWaveForecast } from '../requests/models/MetoceanWaveForecast';
import { MetoceanWindForecast } from '../requests/models/MetoceanWindForecast';
import { MooredVessel } from '../requests/models/MooredVessel';
import { MooringLineCharacteristicsInterpolationParameters } from '../requests/models/MooringLineCharacteristicsInterpolationParameters';
import { MooringLineCharacteristicsRanges } from '../requests/models/MooringLineCharacteristicsRanges';
import { MooringLineConfiguration } from '../requests/models/MooringLineConfiguration';
import { MotionType } from '../requests/models/MotionType';
import { NumericParameterRange } from '../requests/models/NumericParameterRange';
import { Organisation } from '../requests/models/Organisation';
import { OrganisationSummary } from '../requests/models/OrganisationSummary';
import { PredictiveForecast } from '../requests/models/PredictiveForecast';
import { SafetyPrediction } from '../requests/models/SafetyPrediction';
import { SafetyPredictionResponse } from '../requests/models/SafetyPredictionResponse';
import { SafetyPredictionTrigger } from '../requests/models/SafetyPredictionTrigger';
import { SafetyPredictionType } from '../requests/models/SafetyPredictionType';
import { SafetyPredictionsResponse } from '../requests/models/SafetyPredictionsResponse';
import { SmartMooringLevelLabel } from '../requests/models/SmartMooringLevelLabel';
import { ThresholdType } from '../requests/models/ThresholdType';
import { User } from '../requests/models/User';
import { UserPreferences } from '../requests/models/UserPreferences';
import { Vessel } from '../requests/models/Vessel';
import { VesselCharacteristicsLabel } from '../requests/models/VesselCharacteristicsLabel';
import { VesselClassDefinition } from '../requests/models/VesselClassDefinition';
import { VesselClearanceAssessment } from '../requests/models/VesselClearanceAssessment';
import { VesselClearanceAssessmentParameters } from '../requests/models/VesselClearanceAssessmentParameters';
import { VesselClearanceBollardStatus } from '../requests/models/VesselClearanceBollardStatus';
import { VesselClearanceForecast } from '../requests/models/VesselClearanceForecast';
import { VesselClearanceForecastTimeStamp } from '../requests/models/VesselClearanceForecastTimeStamp';
import { VesselClearanceHistoricalMonthlyWindProbability } from '../requests/models/VesselClearanceHistoricalMonthlyWindProbability';
import { VesselClearanceHistoricalWindProbabilities } from '../requests/models/VesselClearanceHistoricalWindProbabilities';
import { VesselClearanceHistoricalWindProbability } from '../requests/models/VesselClearanceHistoricalWindProbability';
import { VesselClearanceSupportedVesselType } from '../requests/models/VesselClearanceSupportedVesselType';
import { VesselClearanceWindForecast } from '../requests/models/VesselClearanceWindForecast';
import { VesselDisposition } from '../requests/models/VesselDisposition';
import { VesselSearchRequest } from '../requests/models/VesselSearchRequest';
import { VesselType } from '../requests/models/VesselType';
import { VesselTypeCharacteristicsParameterRanges } from '../requests/models/VesselTypeCharacteristicsParameterRanges';
import { VesselTypeCharacteristicsParameters } from '../requests/models/VesselTypeCharacteristicsParameters';
import { VesselTypeClassDefinitions } from '../requests/models/VesselTypeClassDefinitions';
import { VesselTypeInterpolationParameters } from '../requests/models/VesselTypeInterpolationParameters';
import { VesselVesselTypeParameters } from '../requests/models/VesselVesselTypeParameters';
import { WindAmplificationFactor } from '../requests/models/WindAmplificationFactor';
import { WindAmplificationFactorsPerDmaCase } from '../requests/models/WindAmplificationFactorsPerDmaCase';
import { WindSpeedType } from '../requests/models/WindSpeedType';
import { DmaLibraryService } from '../requests/services/DmaLibraryService';
import { FeedbackService } from '../requests/services/FeedbackService';
import { MetoceanService } from '../requests/services/MetoceanService';
import { ProfileService } from '../requests/services/ProfileService';
import { SafetyPredictionService } from '../requests/services/SafetyPredictionService';
import { VesselClearanceService } from '../requests/services/VesselClearanceService';

export const useVesselClearanceServiceCalculateVesselClearanceAssessment = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof VesselClearanceService.calculateVesselClearanceAssessment>>,
      unknown,
      {
        xSelectedOrganisationId: string;
        requestBody: VesselClearanceAssessmentParameters;
      },
      unknown
    >,
    'mutationFn'
  >
) =>
  useMutation(
    ({ xSelectedOrganisationId, requestBody }) => VesselClearanceService.calculateVesselClearanceAssessment(xSelectedOrganisationId, requestBody),
    options
  );
export const useVesselClearanceServiceVesselTypeCharacteristicsByVesselTypeKey = 'VesselClearanceServiceVesselTypeCharacteristicsByVesselType';
export const useVesselClearanceServiceVesselTypeCharacteristicsByVesselType = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    vesselType,
  }: {
    vesselType: VesselClearanceSupportedVesselType;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof VesselClearanceService.vesselTypeCharacteristicsByVesselType>>,
      unknown,
      Awaited<ReturnType<typeof VesselClearanceService.vesselTypeCharacteristicsByVesselType>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useVesselClearanceServiceVesselTypeCharacteristicsByVesselTypeKey, ...(queryKey ?? [{ vesselType }])],
    () => VesselClearanceService.vesselTypeCharacteristicsByVesselType(vesselType),
    options
  );
export const useVesselClearanceServiceInterpolateVesselTypeCharacteristics = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof VesselClearanceService.interpolateVesselTypeCharacteristics>>,
      unknown,
      {
        vesselType: VesselClearanceSupportedVesselType;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ vesselType }) => VesselClearanceService.interpolateVesselTypeCharacteristics(vesselType), options);
export const useVesselClearanceServiceVesselCharacteristicsByImoNumberKey = 'VesselClearanceServiceVesselCharacteristicsByImoNumber';
export const useVesselClearanceServiceVesselCharacteristicsByImoNumber = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    imoNumber,
  }: {
    imoNumber: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof VesselClearanceService.vesselCharacteristicsByImoNumber>>,
      unknown,
      Awaited<ReturnType<typeof VesselClearanceService.vesselCharacteristicsByImoNumber>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useVesselClearanceServiceVesselCharacteristicsByImoNumberKey, ...(queryKey ?? [{ imoNumber }])],
    () => VesselClearanceService.vesselCharacteristicsByImoNumber(imoNumber),
    options
  );
export const useVesselClearanceServiceInterpolateVesselTypeParameters = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof VesselClearanceService.interpolateVesselTypeParameters>>,
      unknown,
      {
        vesselType: VesselClearanceSupportedVesselType;
        requestBody: VesselTypeInterpolationParameters;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ vesselType, requestBody }) => VesselClearanceService.interpolateVesselTypeParameters(vesselType, requestBody), options);
export const useVesselClearanceServiceMooringLineCharacteristics = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof VesselClearanceService.mooringLineCharacteristics>>,
      unknown,
      {
        requestBody: MooringLineCharacteristicsInterpolationParameters;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => VesselClearanceService.mooringLineCharacteristics(requestBody), options);
export const useVesselClearanceServiceGetHistoricalWindProbabilitiesKey = 'VesselClearanceServiceGetHistoricalWindProbabilities';
export const useVesselClearanceServiceGetHistoricalWindProbabilities = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthId,
    lineWllSurpassedForBeaufort,
    lineMblSurpassedForBeaufort,
    bollardThresholdSurpassedForBeaufort,
    bollardSwlSurpassedForBeaufort,
  }: {
    xSelectedOrganisationId: string;
    berthId: string;
    lineWllSurpassedForBeaufort: number;
    lineMblSurpassedForBeaufort: number;
    bollardThresholdSurpassedForBeaufort: number;
    bollardSwlSurpassedForBeaufort: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof VesselClearanceService.getHistoricalWindProbabilities>>,
      unknown,
      Awaited<ReturnType<typeof VesselClearanceService.getHistoricalWindProbabilities>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [
      useVesselClearanceServiceGetHistoricalWindProbabilitiesKey,
      ...(queryKey ?? [
        {
          xSelectedOrganisationId,
          berthId,
          lineWllSurpassedForBeaufort,
          lineMblSurpassedForBeaufort,
          bollardThresholdSurpassedForBeaufort,
          bollardSwlSurpassedForBeaufort,
        },
      ]),
    ],
    () =>
      VesselClearanceService.getHistoricalWindProbabilities(
        xSelectedOrganisationId,
        berthId,
        lineWllSurpassedForBeaufort,
        lineMblSurpassedForBeaufort,
        bollardThresholdSurpassedForBeaufort,
        bollardSwlSurpassedForBeaufort
      ),
    options
  );
export const useVesselClearanceServiceListBerthsKey = 'VesselClearanceServiceListBerths';
export const useVesselClearanceServiceListBerths = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
  }: {
    xSelectedOrganisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof VesselClearanceService.listBerths>>,
      unknown,
      Awaited<ReturnType<typeof VesselClearanceService.listBerths>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useVesselClearanceServiceListBerthsKey, ...(queryKey ?? [{ xSelectedOrganisationId }])],
    () => VesselClearanceService.listBerths(xSelectedOrganisationId),
    options
  );
export const useVesselClearanceServiceSearchVessel = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof VesselClearanceService.searchVessel>>,
      unknown,
      {
        requestBody: VesselSearchRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => VesselClearanceService.searchVessel(requestBody), options);
export const useSafetyPredictionServiceGetBaseOfSimulationKey = 'SafetyPredictionServiceGetBaseOfSimulation';
export const useSafetyPredictionServiceGetBaseOfSimulation = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    dmaCaseIdentifier,
  }: {
    xSelectedOrganisationId: string;
    dmaCaseIdentifier: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getBaseOfSimulation>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getBaseOfSimulation>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useSafetyPredictionServiceGetBaseOfSimulationKey, ...(queryKey ?? [{ xSelectedOrganisationId, dmaCaseIdentifier }])],
    () => SafetyPredictionService.getBaseOfSimulation(xSelectedOrganisationId, dmaCaseIdentifier),
    options
  );
export const useSafetyPredictionServiceGetBerthsKey = 'SafetyPredictionServiceGetBerths';
export const useSafetyPredictionServiceGetBerths = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    startTime,
    numberOfDays,
  }: {
    xSelectedOrganisationId: string;
    startTime: string;
    numberOfDays?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getBerths>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getBerths>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useSafetyPredictionServiceGetBerthsKey, ...(queryKey ?? [{ xSelectedOrganisationId, startTime, numberOfDays }])],
    () => SafetyPredictionService.getBerths(xSelectedOrganisationId, startTime, numberOfDays),
    options
  );
export const useSafetyPredictionServiceGetDailyBerthsSafetyWorstWarningsKey = 'SafetyPredictionServiceGetDailyBerthsSafetyWorstWarnings';
export const useSafetyPredictionServiceGetDailyBerthsSafetyWorstWarnings = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    startTime,
    endTime,
  }: {
    xSelectedOrganisationId: string;
    startTime: string;
    endTime?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getDailyBerthsSafetyWorstWarnings>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getDailyBerthsSafetyWorstWarnings>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useSafetyPredictionServiceGetDailyBerthsSafetyWorstWarningsKey, ...(queryKey ?? [{ xSelectedOrganisationId, startTime, endTime }])],
    () => SafetyPredictionService.getDailyBerthsSafetyWorstWarnings(xSelectedOrganisationId, startTime, endTime),
    options
  );
export const useSafetyPredictionServiceGetBerthsSafetyWarningsByDateKey = 'SafetyPredictionServiceGetBerthsSafetyWarningsByDate';
export const useSafetyPredictionServiceGetBerthsSafetyWarningsByDate = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    dateTime,
    berthIds,
    withAisVesselsOnly,
  }: {
    xSelectedOrganisationId: string;
    dateTime: string;
    berthIds?: Array<string>;
    withAisVesselsOnly?: boolean;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getBerthsSafetyWarningsByDate>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getBerthsSafetyWarningsByDate>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [
      useSafetyPredictionServiceGetBerthsSafetyWarningsByDateKey,
      ...(queryKey ?? [{ xSelectedOrganisationId, dateTime, berthIds, withAisVesselsOnly }]),
    ],
    () => SafetyPredictionService.getBerthsSafetyWarningsByDate(xSelectedOrganisationId, dateTime, berthIds, withAisVesselsOnly),
    options
  );
export const useSafetyPredictionServiceGetBerthKey = 'SafetyPredictionServiceGetBerth';
export const useSafetyPredictionServiceGetBerth = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthIdentifier,
  }: {
    xSelectedOrganisationId: string;
    berthIdentifier: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getBerth>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getBerth>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useSafetyPredictionServiceGetBerthKey, ...(queryKey ?? [{ xSelectedOrganisationId, berthIdentifier }])],
    () => SafetyPredictionService.getBerth(xSelectedOrganisationId, berthIdentifier),
    options
  );
export const useSafetyPredictionServiceGetBerthDmaCasesKey = 'SafetyPredictionServiceGetBerthDmaCases';
export const useSafetyPredictionServiceGetBerthDmaCases = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthIdentifier,
  }: {
    xSelectedOrganisationId: string;
    berthIdentifier: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getBerthDmaCases>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getBerthDmaCases>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useSafetyPredictionServiceGetBerthDmaCasesKey, ...(queryKey ?? [{ xSelectedOrganisationId, berthIdentifier }])],
    () => SafetyPredictionService.getBerthDmaCases(xSelectedOrganisationId, berthIdentifier),
    options
  );
export const useSafetyPredictionServiceGetBerthDmaCasesWithPredictionKey = 'SafetyPredictionServiceGetBerthDmaCasesWithPrediction';
export const useSafetyPredictionServiceGetBerthDmaCasesWithPrediction = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthIdentifier,
    startTime,
    numberOfDays,
  }: {
    xSelectedOrganisationId: string;
    berthIdentifier: string;
    startTime: string;
    numberOfDays?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getBerthDmaCasesWithPrediction>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getBerthDmaCasesWithPrediction>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [
      useSafetyPredictionServiceGetBerthDmaCasesWithPredictionKey,
      ...(queryKey ?? [{ xSelectedOrganisationId, berthIdentifier, startTime, numberOfDays }]),
    ],
    () => SafetyPredictionService.getBerthDmaCasesWithPrediction(xSelectedOrganisationId, berthIdentifier, startTime, numberOfDays),
    options
  );
export const useSafetyPredictionServicePredictLineForceKey = 'SafetyPredictionServicePredictLineForce';
export const useSafetyPredictionServicePredictLineForce = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthIdentifier,
    dmaCaseId,
    loadingCondition,
    disposition,
    slotSize,
    days,
    dateTime,
    selectedBandwidth,
  }: {
    xSelectedOrganisationId: string;
    berthIdentifier: string;
    dmaCaseId: string;
    loadingCondition: LoadingCondition;
    disposition: VesselDisposition;
    slotSize?: number;
    days?: number;
    dateTime?: string;
    selectedBandwidth?: BandwidthOption;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.predictLineForce>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.predictLineForce>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [
      useSafetyPredictionServicePredictLineForceKey,
      ...(queryKey ?? [
        { xSelectedOrganisationId, berthIdentifier, dmaCaseId, loadingCondition, disposition, slotSize, days, dateTime, selectedBandwidth },
      ]),
    ],
    () =>
      SafetyPredictionService.predictLineForce(
        xSelectedOrganisationId,
        berthIdentifier,
        dmaCaseId,
        loadingCondition,
        disposition,
        slotSize,
        days,
        dateTime,
        selectedBandwidth
      ),
    options
  );
export const useSafetyPredictionServicePredictFenderDeflectionKey = 'SafetyPredictionServicePredictFenderDeflection';
export const useSafetyPredictionServicePredictFenderDeflection = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthIdentifier,
    dmaCaseId,
    loadingCondition,
    disposition,
    slotSize,
    days,
    dateTime,
    selectedBandwidth,
  }: {
    xSelectedOrganisationId: string;
    berthIdentifier: string;
    dmaCaseId: string;
    loadingCondition: LoadingCondition;
    disposition: VesselDisposition;
    slotSize?: number;
    days?: number;
    dateTime?: string;
    selectedBandwidth?: BandwidthOption;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.predictFenderDeflection>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.predictFenderDeflection>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [
      useSafetyPredictionServicePredictFenderDeflectionKey,
      ...(queryKey ?? [
        { xSelectedOrganisationId, berthIdentifier, dmaCaseId, loadingCondition, disposition, slotSize, days, dateTime, selectedBandwidth },
      ]),
    ],
    () =>
      SafetyPredictionService.predictFenderDeflection(
        xSelectedOrganisationId,
        berthIdentifier,
        dmaCaseId,
        loadingCondition,
        disposition,
        slotSize,
        days,
        dateTime,
        selectedBandwidth
      ),
    options
  );
export const useSafetyPredictionServicePredictMotionKey = 'SafetyPredictionServicePredictMotion';
export const useSafetyPredictionServicePredictMotion = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthIdentifier,
    motionType,
    dmaCaseId,
    loadingCondition,
    disposition,
    slotSize,
    days,
    dateTime,
    selectedBandwidth,
  }: {
    xSelectedOrganisationId: string;
    berthIdentifier: string;
    motionType: MotionType;
    dmaCaseId: string;
    loadingCondition: LoadingCondition;
    disposition: VesselDisposition;
    slotSize?: number;
    days?: number;
    dateTime?: string;
    selectedBandwidth?: BandwidthOption;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.predictMotion>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.predictMotion>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [
      useSafetyPredictionServicePredictMotionKey,
      ...(queryKey ?? [
        {
          xSelectedOrganisationId,
          berthIdentifier,
          motionType,
          dmaCaseId,
          loadingCondition,
          disposition,
          slotSize,
          days,
          dateTime,
          selectedBandwidth,
        },
      ]),
    ],
    () =>
      SafetyPredictionService.predictMotion(
        xSelectedOrganisationId,
        berthIdentifier,
        motionType,
        dmaCaseId,
        loadingCondition,
        disposition,
        slotSize,
        days,
        dateTime,
        selectedBandwidth
      ),
    options
  );
export const useSafetyPredictionServicePredictBollardForcesKey = 'SafetyPredictionServicePredictBollardForces';
export const useSafetyPredictionServicePredictBollardForces = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthIdentifier,
    dmaCaseId,
    loadingCondition,
    disposition,
    slotSize,
    days,
    dateTime,
    selectedBandwidth,
  }: {
    xSelectedOrganisationId: string;
    berthIdentifier: string;
    dmaCaseId: string;
    loadingCondition: LoadingCondition;
    disposition: VesselDisposition;
    slotSize?: number;
    days?: number;
    dateTime?: string;
    selectedBandwidth?: BandwidthOption;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.predictBollardForces>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.predictBollardForces>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [
      useSafetyPredictionServicePredictBollardForcesKey,
      ...(queryKey ?? [
        { xSelectedOrganisationId, berthIdentifier, dmaCaseId, loadingCondition, disposition, slotSize, days, dateTime, selectedBandwidth },
      ]),
    ],
    () =>
      SafetyPredictionService.predictBollardForces(
        xSelectedOrganisationId,
        berthIdentifier,
        dmaCaseId,
        loadingCondition,
        disposition,
        slotSize,
        days,
        dateTime,
        selectedBandwidth
      ),
    options
  );
export const useSafetyPredictionServiceGetMooredVesselsKey = 'SafetyPredictionServiceGetMooredVessels';
export const useSafetyPredictionServiceGetMooredVessels = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    dateTime,
  }: {
    xSelectedOrganisationId: string;
    dateTime?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getMooredVessels>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getMooredVessels>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useSafetyPredictionServiceGetMooredVesselsKey, ...(queryKey ?? [{ xSelectedOrganisationId, dateTime }])],
    () => SafetyPredictionService.getMooredVessels(xSelectedOrganisationId, dateTime),
    options
  );
export const useSafetyPredictionServiceGetMooredVesselsForBerthKey = 'SafetyPredictionServiceGetMooredVesselsForBerth';
export const useSafetyPredictionServiceGetMooredVesselsForBerth = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthIdentifier,
    dateTime,
  }: {
    xSelectedOrganisationId: string;
    berthIdentifier: string;
    dateTime?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getMooredVesselsForBerth>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getMooredVesselsForBerth>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useSafetyPredictionServiceGetMooredVesselsForBerthKey, ...(queryKey ?? [{ xSelectedOrganisationId, berthIdentifier, dateTime }])],
    () => SafetyPredictionService.getMooredVesselsForBerth(xSelectedOrganisationId, berthIdentifier, dateTime),
    options
  );
export const useSafetyPredictionServiceGetMooredVesselForBerthKey = 'SafetyPredictionServiceGetMooredVesselForBerth';
export const useSafetyPredictionServiceGetMooredVesselForBerth = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthIdentifier,
    mmsi,
    dateTime,
  }: {
    xSelectedOrganisationId: string;
    berthIdentifier: string;
    mmsi: string;
    dateTime?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SafetyPredictionService.getMooredVesselForBerth>>,
      unknown,
      Awaited<ReturnType<typeof SafetyPredictionService.getMooredVesselForBerth>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useSafetyPredictionServiceGetMooredVesselForBerthKey, ...(queryKey ?? [{ xSelectedOrganisationId, berthIdentifier, mmsi, dateTime }])],
    () => SafetyPredictionService.getMooredVesselForBerth(xSelectedOrganisationId, berthIdentifier, mmsi, dateTime),
    options
  );
export const useProfileServiceGetUserKey = 'ProfileServiceGetUser';
export const useProfileServiceGetUser = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof ProfileService.getUser>>, unknown, Awaited<ReturnType<typeof ProfileService.getUser>>, unknown[]>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => useQuery([useProfileServiceGetUserKey, ...(queryKey ?? [])], () => ProfileService.getUser(), options);
export const useProfileServiceUpdateUserPreferences = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof ProfileService.updateUserPreferences>>,
      unknown,
      {
        requestBody: UserPreferences;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => ProfileService.updateUserPreferences(requestBody), options);
export const useProfileServiceGetOrganisationKey = 'ProfileServiceGetOrganisation';
export const useProfileServiceGetOrganisation = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
  }: {
    xSelectedOrganisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof ProfileService.getOrganisation>>,
      unknown,
      Awaited<ReturnType<typeof ProfileService.getOrganisation>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useProfileServiceGetOrganisationKey, ...(queryKey ?? [{ xSelectedOrganisationId }])],
    () => ProfileService.getOrganisation(xSelectedOrganisationId),
    options
  );
export const useProfileServiceGetDmaCasesKey = 'ProfileServiceGetDmaCases';
export const useProfileServiceGetDmaCases = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
  }: {
    xSelectedOrganisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof ProfileService.getDmaCases>>,
      unknown,
      Awaited<ReturnType<typeof ProfileService.getDmaCases>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useProfileServiceGetDmaCasesKey, ...(queryKey ?? [{ xSelectedOrganisationId }])],
    () => ProfileService.getDmaCases(xSelectedOrganisationId),
    options
  );
export const useProfileServiceGetPurchasedDmaCasesKey = 'ProfileServiceGetPurchasedDmaCases';
export const useProfileServiceGetPurchasedDmaCases = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
  }: {
    xSelectedOrganisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof ProfileService.getPurchasedDmaCases>>,
      unknown,
      Awaited<ReturnType<typeof ProfileService.getPurchasedDmaCases>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useProfileServiceGetPurchasedDmaCasesKey, ...(queryKey ?? [{ xSelectedOrganisationId }])],
    () => ProfileService.getPurchasedDmaCases(xSelectedOrganisationId),
    options
  );
export const useProfileServiceGetEvaluationDmaCasesKey = 'ProfileServiceGetEvaluationDmaCases';
export const useProfileServiceGetEvaluationDmaCases = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
  }: {
    xSelectedOrganisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof ProfileService.getEvaluationDmaCases>>,
      unknown,
      Awaited<ReturnType<typeof ProfileService.getEvaluationDmaCases>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useProfileServiceGetEvaluationDmaCasesKey, ...(queryKey ?? [{ xSelectedOrganisationId }])],
    () => ProfileService.getEvaluationDmaCases(xSelectedOrganisationId),
    options
  );
export const useMetoceanServiceGetVesselClearanceForecastsKey = 'MetoceanServiceGetVesselClearanceForecasts';
export const useMetoceanServiceGetVesselClearanceForecasts = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthId,
    bollardThresholdSurpassedForBeaufort,
    bollardSwlSurpassedForBeaufort,
    days,
    dateTime,
  }: {
    xSelectedOrganisationId: string;
    berthId: string;
    bollardThresholdSurpassedForBeaufort: number;
    bollardSwlSurpassedForBeaufort: number;
    days?: number;
    dateTime?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof MetoceanService.getVesselClearanceForecasts>>,
      unknown,
      Awaited<ReturnType<typeof MetoceanService.getVesselClearanceForecasts>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [
      useMetoceanServiceGetVesselClearanceForecastsKey,
      ...(queryKey ?? [{ xSelectedOrganisationId, berthId, bollardThresholdSurpassedForBeaufort, bollardSwlSurpassedForBeaufort, days, dateTime }]),
    ],
    () =>
      MetoceanService.getVesselClearanceForecasts(
        xSelectedOrganisationId,
        berthId,
        bollardThresholdSurpassedForBeaufort,
        bollardSwlSurpassedForBeaufort,
        days,
        dateTime
      ),
    options
  );
export const useMetoceanServiceGetForecastsKey = 'MetoceanServiceGetForecasts';
export const useMetoceanServiceGetForecasts = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    xSelectedOrganisationId,
    berthId,
    days,
  }: {
    xSelectedOrganisationId: string;
    berthId: string;
    days?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof MetoceanService.getForecasts>>,
      unknown,
      Awaited<ReturnType<typeof MetoceanService.getForecasts>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useMetoceanServiceGetForecastsKey, ...(queryKey ?? [{ xSelectedOrganisationId, berthId, days }])],
    () => MetoceanService.getForecasts(xSelectedOrganisationId, berthId, days),
    options
  );
export const useFeedbackServiceSendFeedback = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof FeedbackService.sendFeedback>>,
      unknown,
      {
        requestBody: FeedbackRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => FeedbackService.sendFeedback(requestBody), options);
export const useDmaLibraryServiceGetLibraryDmaCasesKey = 'DmaLibraryServiceGetLibraryDmaCases';
export const useDmaLibraryServiceGetLibraryDmaCases = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof DmaLibraryService.getLibraryDmaCases>>,
      unknown,
      Awaited<ReturnType<typeof DmaLibraryService.getLibraryDmaCases>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => useQuery([useDmaLibraryServiceGetLibraryDmaCasesKey, ...(queryKey ?? [])], () => DmaLibraryService.getLibraryDmaCases(), options);
export const useDmaLibraryServiceGetVesselClassesKey = 'DmaLibraryServiceGetVesselClasses';
export const useDmaLibraryServiceGetVesselClasses = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof DmaLibraryService.getVesselClasses>>,
      unknown,
      Awaited<ReturnType<typeof DmaLibraryService.getVesselClasses>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => useQuery([useDmaLibraryServiceGetVesselClassesKey, ...(queryKey ?? [])], () => DmaLibraryService.getVesselClasses(), options);
