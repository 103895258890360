import { Paper, PaperProps, styled } from '@mui/material';

const Wrapper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(1),
  position: 'relative',
}));

function Bar(props: PaperProps) {
  return <Wrapper elevation={0} {...props} />;
}

export default Bar;
