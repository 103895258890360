import { memo, useMemo } from 'react';
import { Grid, PaletteColor, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateCalendar, DayCalendarSkeleton, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { useSafetyPredictionServiceGetDailyBerthsSafetyWorstWarnings } from '@/api/ui/queries';
import { BerthSafetyWarningSummary } from '@/api/ui/requests';
import { safetyPredictionTypeColor } from '@/features/color-utils';
import useOrganisation from '@/hooks/useOrganisation';
import { DATE_FORMAT } from '@/types';

type WarningDayProps = {
  day: Moment;
  value: number;
  color: PaletteColor | undefined;
};

const WarningDay = (
  props: PickersDayProps<Moment> & {
    warningByDays: WarningDayProps[];
    maxDay: Moment;
    onDateSelected: (day: Moment) => void;
    theme: Theme;
  }
) => {
  const { theme, maxDay, warningByDays = [], day, onDateSelected, ...other } = props;

  const isSelectedDayHasWarning = (d: Moment) => d && d.isSame(day, 'day');
  const dayWithWarning = warningByDays.find((w) => isSelectedDayHasWarning(w.day));
  const onWarningDaySelected = (d: Moment) => isSelectedDayHasWarning(dayWithWarning?.day) && onDateSelected(d);
  const backgroundColor = dayWithWarning?.color ?? (day.isAfter(maxDay) ? undefined : theme.palette.secondary);
  const dateAsString = day.format(DATE_FORMAT);

  return (
    <PickersDay
      {...other}
      day={day}
      key={dateAsString}
      data-testid={dateAsString}
      disableHighlightToday={true}
      onDaySelect={onWarningDaySelected}
      sx={{
        fontWeight: 600,
        color: backgroundColor?.contrastText,
        bgcolor: backgroundColor?.main,
        '&:hover, &:focus': {
          bgcolor: backgroundColor?.main,
        },
      }}
    />
  );
};

type CalendarCommonProps = {
  warningFilter: (_: BerthSafetyWarningSummary) => boolean;
  onDateSelected: (_: Moment) => void;
};

const extractWorstWarnings = (theme: Theme, warnings: BerthSafetyWarningSummary[], warningFilter: (warning: BerthSafetyWarningSummary) => boolean) =>
  warnings.filter(warningFilter).reduce((acc, { percentage, safetyPredictionType, isCustomerDefinedType }) => {
    if (acc.worstPercentage > percentage) return acc;
    return {
      worstPercentage: percentage,
      worstIndicatorColor: safetyPredictionTypeColor(theme, safetyPredictionType, isCustomerDefinedType),
    };
  }, {} as unknown as { worstPercentage: number; worstIndicatorColor: PaletteColor });

const MonthlyWarningCalendar = ({
  organisationId,
  startDate,
  endDate,
  warningFilter,
  onDateSelected,
}: {
  organisationId: string;
  startDate: Moment;
  endDate: Moment;
} & CalendarCommonProps) => {
  const theme = useTheme();
  const start = startDate.toISOString(true);
  const end = endDate.toISOString(true);
  const { isLoading, data: dailyWarnings } = useSafetyPredictionServiceGetDailyBerthsSafetyWorstWarnings(
    {
      xSelectedOrganisationId: organisationId,
      startTime: start,
      endTime: end,
    },
    [organisationId, start, end],
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const warningByDays = useMemo(
    () =>
      dailyWarnings
        ?.map(({ day, warnings }) => {
          if (warnings.length == 0) return null;

          const { worstPercentage, worstIndicatorColor } = extractWorstWarnings(theme, warnings, warningFilter);
          return {
            day: moment(day),
            value: worstPercentage,
            color: worstIndicatorColor,
          };
        })
        .filter(Boolean) ?? [],
    [dailyWarnings, warningFilter]
  );

  const monthAsString = startDate.format('MMMM YYYY');

  return (
    <Grid item xs={6} sm={4} lg={3} display="grid" justifyItems={'center'} key={monthAsString}>
      <Typography variant="highlightSemiBold">{monthAsString}</Typography>
      <DateCalendar
        slots={{
          day: WarningDay,
        }}
        slotProps={{
          calendarHeader: { sx: { display: 'none' } },
          day: {
            onDateSelected,
            warningByDays,
            theme,
          } as unknown,
        }}
        loading={isLoading}
        renderLoading={() => <DayCalendarSkeleton />}
        minDate={startDate}
        maxDate={endDate}
        disableFuture={true}
      />
    </Grid>
  );
};

type HistoricWarningCalendarProps = {
  selectedYear: number;
} & CalendarCommonProps;

function HistoricWarningCalendar({ selectedYear, warningFilter, onDateSelected }: HistoricWarningCalendarProps) {
  const { organisationId } = useOrganisation();
  const monthlyCalendars = useMemo(() => {
    return moment
      .months()
      .reverse()
      .map((month) => {
        const distantMonth = moment().year(selectedYear).month(month);
        const startOfMonth = moment(distantMonth).startOf('month');
        const endOfMonth = moment(distantMonth).endOf('month');

        return (
          <MonthlyWarningCalendar
            organisationId={organisationId}
            startDate={startOfMonth}
            endDate={endOfMonth}
            warningFilter={warningFilter}
            key={startOfMonth.format('MMMM YYYY')}
            onDateSelected={onDateSelected}
          />
        );
      });
  }, [selectedYear, organisationId, warningFilter, onDateSelected]);

  return (
    <Grid container spacing={0.4}>
      {monthlyCalendars}
    </Grid>
  );
}

export default memo(HistoricWarningCalendar);
