/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The trigger that lead to this safety prediction
 */
export enum SafetyPredictionTrigger {
  NONE = 'NONE',
  LINE_FORCE = 'LINE_FORCE',
}
