import { ReactElement, useContext, useLayoutEffect } from 'react';
import { useDocumentTitle } from 'usehooks-ts';
import SmartMooringContext from '@/features/Common/SmartMooringContext';
import { Analytics } from '@/services/analytics';

function usePageTitle(title: string, titleIcon?: ReactElement | undefined) {
  useDocumentTitle(title);

  const { pageTitle, pageTitleIcon, setPageTitle, setPageTitleIcon } = useContext(SmartMooringContext);

  useLayoutEffect(() => {
    if (title !== pageTitle) {
      setPageTitle(title);
      Analytics.pageview(title);
    }

    if (titleIcon && !titleIcon.key) {
      throw new SyntaxError("Missing key for prop: 'titleIcon'.");
    }

    if (titleIcon?.key !== pageTitleIcon?.key) {
      setPageTitleIcon(titleIcon);
    }
  }, [setPageTitleIcon, setPageTitle, pageTitleIcon?.key, titleIcon, pageTitle, title]);
}

export default usePageTitle;
